import { Button } from "@headlessui/react";
import ArrowLeft from "./Icons/ArrowLeft";

interface BackButtonProps {
  onClick: () => void;
}

export default function BackButton({ onClick }: BackButtonProps) {
  return (
    <Button onClick={onClick} className="gap-3 flex flex-row items-center">
      <ArrowLeft />
      <span className="text-xl font-bold font-mono">Back</span>
    </Button>
  );
}
