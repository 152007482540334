"use client";

import { Tab, TabGroup, TabList } from "@headlessui/react";
import classNames from "classnames";

interface TabProps {
  tabs: string[];
  selectedIndex: number;
  setCurrentTab: (tab: number) => void;
}

export default function Tabs({ tabs, selectedIndex, setCurrentTab }: TabProps) {
  return (
    <TabGroup selectedIndex={selectedIndex} onChange={setCurrentTab}>
      <TabList className="rounded-md border border-gray-900 bg-gray-200 w-fit">
        {tabs.map((tab, index) => (
          <Tab
            className={classNames(
              "data-[selected]:bg-gray-100 data-[selected]:text-gray-900 data-[selected]:font-bold data-[hover]:bg-gray-400 px-3 h-9 font-sans text-lg font-normal leading-5 focus:outline-none focus:border-none",
              {
                "rounded-l-md": index === 0,
                "rounded-r-md": index === tabs.length - 1,
              },
            )}
            key={tab}
          >
            {tab}
          </Tab>
        ))}
      </TabList>
    </TabGroup>
  );
}
