import classNames from "classnames";
import type { SVGProps } from "react";

interface ChevronRightProps extends SVGProps<SVGSVGElement> {
  disabled?: boolean;
}

const ChevronRight = ({ disabled, ...props }: ChevronRightProps) => (
  <svg
    width={16}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      d="M0 0h16v16H0z"
      style={{
        mixBlendMode: "multiply",
      }}
      transform="translate(.5)"
    />
    <path
      className={classNames({
        "fill-gray-600": disabled,
        "fill-gray-900": !disabled,
      })}
      d="m11.5 8-5 5-.7-.7L10.1 8 5.8 3.7l.7-.7 5 5Z"
    />
  </svg>
);
export default ChevronRight;
