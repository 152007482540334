import type { Address } from "viem";
import type { Config } from "@wagmi/core";

export function shortenAddress(address: Address): string {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}

export const openTransactionBlockExplorer = (
  hash: string,
  config: Config,
): void => {
  const blockExplorer = config.chains[0].blockExplorers?.default.url;
  if (blockExplorer) {
    window.open(`${blockExplorer}/tx/${hash}`, "_blank");
  }
};

export const openAddressBlockExplorer = (
  address: string,
  config: Config,
): void => {
  const blockExplorer = config.chains[0].blockExplorers?.default.url;
  if (blockExplorer) {
    window.open(`${blockExplorer}/address/${address}`, "_blank");
  }
};
