import type { SVGProps } from "react";
const ArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M0 0h32v32H0z"
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      fill="#222"
      d="m14 26 1.41-1.41L7.83 17H28v-2H7.83l7.58-7.59L14 6 4 16l10 10Z"
    />
  </svg>
);
export default ArrowLeft;
