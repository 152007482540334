import type { SVGProps } from "react";
const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      d="M0 0h32v32H0z"
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      fill="#222"
      d="M24 9.4 22.6 8 16 14.6 9.4 8 8 9.4l6.6 6.6L8 22.6 9.4 24l6.6-6.6 6.6 6.6 1.4-1.4-6.6-6.6L24 9.4Z"
    />
  </svg>
);
export default Close;
