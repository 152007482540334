import type { SVGProps } from "react";
const Logout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      d="M0 0h16v16H0z"
      style={{
        mixBlendMode: "multiply",
      }}
    />
    <path
      fill="#222"
      d="M3 15h6a1.001 1.001 0 0 0 1-1v-1.5H9V14H3V2h6v1.5h1V2a1.001 1.001 0 0 0-1-1H3a1.001 1.001 0 0 0-1 1v12a1.001 1.001 0 0 0 1 1Z"
    />
    <path
      fill="#222"
      d="M10.293 10.293 12.086 8.5H5v-1h7.086l-1.793-1.793L11 5l3 3-3 3-.707-.707Z"
    />
  </svg>
);
export default Logout;
